exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-diversity-js": () => import("./../../../src/pages/diversity.js" /* webpackChunkName: "component---src-pages-diversity-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-people-js": () => import("./../../../src/pages/people.js" /* webpackChunkName: "component---src-pages-people-js" */),
  "component---src-pages-supporting-member-js": () => import("./../../../src/pages/supporting_member.js" /* webpackChunkName: "component---src-pages-supporting-member-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-wp-post-uri-js": () => import("./../../../src/pages/{WpPost.uri}.js" /* webpackChunkName: "component---src-pages-wp-post-uri-js" */),
  "component---src-templates-all-news-js": () => import("./../../../src/templates/all-news.js" /* webpackChunkName: "component---src-templates-all-news-js" */),
  "component---src-templates-utility-page-js": () => import("./../../../src/templates/utility-page.js" /* webpackChunkName: "component---src-templates-utility-page-js" */)
}

